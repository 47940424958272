import { Link } from 'wouter'
import { useState, useEffect } from 'react'

const Footer = () => {
  const [lastUpdated, setLastUpdated] = useState('Recently')

  useEffect(() => {
    // Check for the admin query param in the URL
    const queryParams = new URLSearchParams(window.location.search)
    const isAdmin = queryParams.get('admin') === 'true'
    // Attempt to retrieve the server-injected timestamp element's content
    // If it doesn't exist, use the current timestamp as a fallback.
    let serverTimestamp = document.getElementById('last-updated')?.textContent
    if (!serverTimestamp) {
      serverTimestamp = new Date().toISOString()
    }

    const date = new Date(serverTimestamp)
    if (!isNaN(date.getTime())) {
      // Admin sees full info; regular users see just the local date.
      setLastUpdated(
        isAdmin ? date.toLocaleString() : date.toLocaleDateString()
      )
    } else {
      // If the date isn't valid, fallback to the raw text.
      setLastUpdated(serverTimestamp)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <footer className="min-h-[10vh] text-gray-600 body-font max-w-screen-lg flex items-end justify-center mx-auto px-5">
      <div className="py-4 flex flex-row justify-between text-gray-500 text-sm gap-1">
        <p>Last updated: {lastUpdated}</p>
        <Link
          onClick={scrollToTop}
          to={'/about'}
          className="hover:text-gray-700"
        >
          {`© Meir Knapp`}
        </Link>
        {/* <p className="select-none">|</p> */}
        {/* <Link
          onClick={scrollToTop}
          to={'/about'}
          className="hover:text-gray-700"
        >
          {`© ${new Date().getFullYear()} Meir Knapp`}
        </Link> */}
      </div>
    </footer>
  )
}

export default Footer
