import React from 'react'
import AIQRCodePage from './AIQRCodePage'
import Heading from '../../components/Heading'
import { ArrowUpRight } from 'lucide-react'
// import { trackEvent } from '../../utils/gtagTracking'

const list = [
  {
    link: 'https://chatgpt.com',
    desc: 'ChatGPT',
    text: 'A powerful and versatile language model from OpenAI. Great for creative writing, brainstorming, and getting quick answers.',
  },
  {
    link: 'https://claude.ai/',
    desc: 'Claude',
    text: 'An AI assistant focused on helpfulness and harmlessness. Known for its strong reasoning and conversational abilities.',
  },
  {
    link: 'https://aistudio.google.com/prompts/new_chat',
    desc: 'Google AI Studio',
    text: "Google's platform for experimenting with their Gemini models. Useful for exploring cutting-edge AI capabilities.",
  },
  {
    link: 'https://web.lmarena.ai/',
    desc: 'AI Models Competition (LM Arena)',
    text: 'A platform to compare and evaluate different language models. See how various models stack up against each other.',
  },
  {
    link: 'https://perchance.org/ai-text-to-image-generator',
    desc: 'Perchance (free, no login)',
    text: 'A collection of free, browser-based AI tools, including a text-to-image generator. Easy to use without any account.',
  },
  {
    link: 'https://openrouter.ai/',
    desc: 'OpenRouter',
    text: 'An API for accessing various AI models through a single integration. Offers flexibility and choice in AI providers.',
  },
  {
    link: 'https://chat.qwenlm.ai/',
    desc: 'Qwen',
    text: 'A language model developed by Alibaba. Known for its strong performance in Chinese and English.',
  },
]

const audioTools = [
  {
    link: 'https://suno.com',
    desc: 'Suno AI',
    text: 'Suno is building a future where anyone can make great music.',
  },
  // You can add more audio tools here in the future
]

const AboutMeAI = () => {
  // trackEvent()
  return (
    <div className="flex flex-col justify-center text-center mx-auto p-4">
      <Heading className="">AI & Me</Heading>
      <section className="mb-8 text-start">
        {/* <h2 className="text-2xl font-semibold mb-2 text-center">AI tools</h2> */}
        {list.map((item) => (
          <div key={item.desc} className="mb-4">
            <h3 className="text-xl font-semibold mb-1 justify-center">
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="group hover:text-blue-500 text-green-600 transition-colors duration-200"
              >
                {item.desc}
                <span className="flex text-sm">
                  {item.link}
                  <ArrowUpRight className="size-4 ml-1" />
                </span>
              </a>
            </h3>
            <p className="mb-2">{item.text}</p>
          </div>
        ))}
        <span>* I use more AI tools. I will add them at some point.</span>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2 text-center">
          Image Generation
        </h2>
        <AIQRCodePage />
      </section>
      <section className="mb-8 text-start">
        <h2 className="text-2xl font-semibold mb-2 text-center">Audio Tools</h2>
        {audioTools.map((tool) => (
          <div key={tool.desc} className="mb-4">
            <h3 className="text-xl font-semibold mb-1 justify-center">
              <a
                href={tool.link}
                target="_blank"
                rel="noopener noreferrer"
                className="group hover:text-blue-500 text-green-600 transition-colors duration-200"
              >
                {tool.desc}
                <span className="flex text-sm">
                  {tool.link}
                  <ArrowUpRight className="size-4 ml-1" />
                </span>
              </a>
            </h3>
            <p className="mb-2">{tool.text}</p>
          </div>
        ))}
      </section>
    </div>
  )
}
export default AboutMeAI
