import { useEffect } from 'react'

/**
 * UrlHelper Component
 *
 * This component checks the URL for a hash (e.g., "#websites") on load and, if an element
 * with the corresponding ID exists, scrolls that element into view. It can be extended
 * with additional URL helper functionalities in the future.
 */
export default function UrlHelper() {
  useEffect(() => {
    const { hash } = window.location
    if (hash) {
      // Remove '#' from the hash to extract the element id
      const id = hash.substring(1)
      const element = document.getElementById(id)
      if (element) {
        // Scroll the element into view smoothly
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [])

  // This helper component doesn't render any UI.
  return null
}
