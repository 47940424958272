import React, { useState } from 'react'
import Heading from '../components/Heading'
import { trackEvent } from '../utils/gtagTracking'
import { Link } from 'wouter'

// Split email into parts to avoid simple scraping - need to test if this helps
const emailUser = 'hello'
const emailDomain = 'meir.pro'

export const AnimatedButton = ({ text, onClick, props }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className="flex justify-center items-center bg-gray-100"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center relative overflow-hidden"
        {...props}
        onClick={(e) => {
          e.preventDefault()
          if (onClick) onClick()
        }}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            if (onClick) onClick()
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            if (onClick) onClick()
          }
        }}
      >
        <span
          className={`absolute left-2 transition-transform duration-300 ${
            isHovered ? 'translate-x-0' : '-translate-x-10 width-0'
          }`}
        >
          →
        </span>
        <span
          className={`transition-transform duration-300 pr-4 ${
            isHovered ? 'translate-x-4' : ''
          }`}
        >
          {text}
        </span>
        <span
          className={`absolute right-2 transition-transform duration-300 ${
            isHovered ? 'translate-x-10' : 'translate-x-0  width-0'
          }`}
        >
          →
        </span>
      </a>
    </div>
  )
}

export const AnimatedContactButton = ({ text = 'Contact Me' }) => {
  return (
    <AnimatedButton
      target="_blank"
      rel="noopener noreferrer"
      href={'#'}
      text={text}
      onClick={() => {
        trackEvent({
          eventName: 'button_click',
          label: 'contact_button',
          category: 'contact',
        })
        const subject = '' // Define the subject
        const body = 'Hello Meir,\n\nI would like to discuss...' // Define the body text
        window.open(
          `mailto:${emailUser}@${emailDomain}?subject=${encodeURIComponent(
            subject
          )}&body=${encodeURIComponent(body)}`,
          '_blank'
        ) // Open email in a new tab with subject and body
      }}
    />
  )
}

const ContactBody = () => {
  return (
    <div className="text-center">
      <Heading>CONTACT</Heading>

      <p className="mb-4">
        Have questions or want to get in touch? Click the button below to send
        me an email!
      </p>
      <div className="mb-4">
        <AnimatedContactButton />
      </div>

      <p className="mb-4">
        I'll do my best to respond within a reasonable timeframe.
      </p>

      {/* <p className="mt-8">
        Want to schedule a meeting?{' '}
        <Link 
          to="/appointments" 
          className="text-blue-600 hover:text-blue-800"
          onClick={() => trackEvent({
            eventName: 'navigation_click',
            label: 'appointments_page',
            category: 'navigation',
          })}
        >
          Book an Appointment
        </Link>
      </p> */}
    </div>
  )
}

export { ContactBody }

const Contact = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 py-8 max-w-screen-md mx-auto">
      <ContactBody />
    </div>
  )
}

export default Contact
