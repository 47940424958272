import React from 'react'
// import Heading from '../components/Heading'
import GoogleCalendar from '../parts/GoogleCalendar'

const Appointments = () => {
  return (
    <div className="w-full min-h-screen flex flex-col items-center">
      <div className="w-full max-w-screen-xl px-4 py-8 min-h-screen">
        {/* <Heading>Book an Appointment</Heading> */}
        {/* <p className="text-center mb-8">
          Select a convenient time slot for our meeting.
        </p> */}
        <GoogleCalendar autoLoad={true} fullScreen={true} />
      </div>
    </div>
  )
}

export default Appointments
