import React, { useEffect } from "react";

const DOMCleanup = () => {
  const removeNoscriptElements = () => {
    // Remove noscript elements
    const noscriptElements = document.querySelectorAll('noscript')
    noscriptElements.forEach((noscriptElement) => {
      if (noscriptElement.parentNode) {
        noscriptElement.parentNode.removeChild(noscriptElement)
      }
    })

    // Remove the script element itself
    const currentScript = document.currentScript
    if (currentScript && currentScript.parentNode) {
      currentScript.parentNode.removeChild(currentScript)
    }
  }

  const removeMetaTags = (attribute, prefix) => {
    const metaTags = document.querySelectorAll(
      `meta[${attribute}^="${prefix}"]`
    )

    metaTags.forEach((tag) => {
      tag.parentNode.removeChild(tag)
    })
  }
  useEffect(() => {
    const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
      navigator.userAgent
    )

    if (!isBot) {
      removeMetaTags('name', 'twitter:')
      removeMetaTags('property', 'og:')
    }
  }, [])

  useEffect(() => {
    removeNoscriptElements()
  }, [])

  return <></>
};

export default DOMCleanup;
