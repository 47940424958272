import React from "react";
// import meirImage from "../assets/images/MeirByDesk.png";
import styled from "styled-components";
import Heading from '../components/Heading'
import { ContactBody } from './Contact'
import { Link } from 'wouter'
import { trackEvent } from '../utils/gtagTracking'
import { Tooltip } from 'react-tooltip'

const StyledImage = styled.img`
  width: 100%;
  max-width: 768px;
  height: auto;
  object-fit: cover;
`

const About = () => {
  const meirImage = './assets/about/Meir-at-desk.webp'
  const trackClick = (text) => {
    trackEvent({
      eventName: 'navigation_click',
      label: text,
      category: 'navigation',
    })
  }
  return (
    <div className="flex flex-col items-center p-8 max-w-screen-md mx-auto font-normal">
      <div className="prose prose-lg space-y-6 ">
        <Heading>About Me</Heading>

        <p className="mb-8">Hi! I'm Meir.</p>

        <p className="mb-8 leading-relaxed ">
          You can find me on
          <button
            onClick={() => {
              trackClick('about-linkedin')
              window.open(
                'https://www.linkedin.com/in/meir-k/',
                '_blank',
                'noopener,noreferrer'
              )
            }}
            className="text-blue-600 hover:text-blue-800 px-1"
          >
            {' LinkedIn '}
          </button>
          or check out my
          <Link
            onClick={() => trackClick('about-resume')}
            to="resume"
            className="text-blue-600 hover:text-blue-800"
          >
            {' resume '}
          </Link>
          for more details about my professional experience.
        </p>

        <p className="mb-8 leading-relaxed ">
          I'm passionate about: gaining new knowledge 📚, sipping hot mint tea
          🫖, and cracking challenging puzzles 🧩.
          <br />
          When I'm not at my desk working, you might find me learning something
          new 📚, experimenting with new recipes in the kitchen 🍳, tinkering
          with hardware projects 🔧, listening to podcasts on Spotify 🎧,
          playing a strategy board game ♟️, or checking out a new
          <Link
            onClick={() => trackClick('about-ai')}
            to="ai"
            className="text-blue-600 hover:text-blue-800"
          >
            {' AI '}
          </Link>
          tool.
        </p>

        <StyledImage
          src={meirImage}
          alt="Meir at desk"
          className="mb-8 pointer-events-none"
          width={2140}
          height={1222}
        />

        <h2 className="text-2xl font-bold mt-12 mb-6">What I Do</h2>
        <p className="mb-8 leading-relaxed pb-8">
          I enjoy building things. I'm particularly interested in creating tools
          that make people's lives easier 🛠️.
        </p>

        <h2 className="text-2xl font-bold mt-12 mb-6">Fun Facts</h2>
        <ul className="list-disc list-inside mb-8 space-y-2">
          <li>I enjoy playing chess ♟️</li>
          <li>
            I like Minecraft, mostly building with redstone in creative mode 🎮
          </li>
          <li>I more than once debugged code in my sleep (literally!) 💤</li>
          <li>
            I practice Jiu-Jitsu and recently won 2nd place in a local BJJ
            tournament 🥋
          </li>
          <li>I'm always trying to learn something new 🎯</li>
          <li>
            I'm sometimes called an{' '}
            <span
              className="cursor-help border-b border-dotted border-gray-400 relative"
              data-tooltip-id="autodidact-tooltip"
              data-tooltip-content="A self-taught person"
            >
              autodidact
            </span>{' '}
            or{' '}
            <span
              className="cursor-help border-b border-dotted border-gray-400 relative"
              data-tooltip-id="polymath-tooltip"
              data-tooltip-content="A person whose expertise spans a significant number of different subject areas"
            >
              polymath
            </span>
            , or both 🧠
          </li>
          <li>I speak multiple programming languages and human languages 🗣️</li>
        </ul>
      </div>
      <div className="mt-40">
        <ContactBody />
      </div>
      <Tooltip id="autodidact-tooltip" />
      <Tooltip id="polymath-tooltip" />
    </div>
  )
}

export default About;
