// import usePageLoad from "../../utils/usePageLoaded";
import "./Nav.css";
import React from 'react'
// eslint-disable-next-line no-unused-vars
import { Link, useLocation } from 'wouter'
import { trackEvent } from '../../utils/gtagTracking'
import { ArrowUpRight } from 'lucide-react'
import { Tooltip } from 'react-tooltip'

const DropdownItem = ({ href, to, label, ext = false }) => {
  const handleClick = () => {
    trackEvent({
      eventName: 'navigation_click',
      label,
      category: 'navigation',
    })
  }

  const baseClasses =
    'px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-green-700'

  return (
    <li className="hover:bg-gray-100 cursor-pointer">
      {ext ? (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={`${baseClasses} flex items-center justify-between`}
          onClick={handleClick}
        >
          {label}
          <ArrowUpRight className="ml-2" />
        </a>
      ) : (
        <Link
          to={to}
          className={`${baseClasses} block w-full h-full`}
          onClick={handleClick}
        >
          {label}
        </Link>
      )}
    </li>
  )
}

const DropdownButton = () => {
  const [location] = useLocation()
  const [isOpen, setIsOpen] = React.useState(false)
  const dropdownRef = React.useRef(null)
  const timeoutRef = React.useRef(null)
  const isActive = location === '/resume'

  const handleOpen = () => {
    clearTimeout(timeoutRef.current)
    setIsOpen(true)
  }

  const handleClose = () => {
    timeoutRef.current = setTimeout(() => setIsOpen(false), 300)
  }

  React.useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [])

  return (
    <li
      className="px-3 select-none relative"
      ref={dropdownRef}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      onFocus={handleOpen}
      onBlur={handleClose}
    >
      <button
        data-tip="Resume"
        className={`hover:text-green-700 ${
          isActive ? 'text-green-500' : ''
        } hover:cursor-pointer`}
      >
        Resume
      </button>
      <Tooltip place="top" type="dark" effect="solid" />

      <div
        className={`absolute top-full pt-2 transition-opacity duration-200 ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        style={{ zIndex: 1000 }}
      >
        <ul className="bg-white shadow-lg rounded-lg py-2 w-48 text-left border border-gray-100">
          <DropdownItem
            ext
            href="https://docs.google.com/document/d/1wIjcRP_AB86pOonYwA8iMF00qE_2mC6GSSum0QVSjdY/view?usp=sharing"
            label="Google Docs"
          />
          <DropdownItem to="/resume" label="Here" />
        </ul>
      </div>
    </li>
  )
}

const NavButton = ({ to, text }) => {
  const [location] = useLocation()
  const isActive = location === to

  return (
    <li className="px-3 select-none" data-tip={text}>
      <Link
        to={to}
        onClick={() =>
          trackEvent({
            eventName: 'navigation_click',
            label: text,
            category: 'navigation',
          })
        }
        className={`hover:text-green-700 ${isActive ? 'text-green-500' : ''}`}
      >
        {text}
      </Link>
      <Tooltip place="top" type="dark" effect="solid" />
    </li>
  )
}

export const Nav = () => {
  return (
    <>
      <header className="max-w-screen-lg m-auto min-h-[10vh]">
        <nav className="px-8 py-4 flex flex-col md:flex-row justify-center sm:justify-between items-center max-w-screen-xl mx-auto">
          <Link to="/" title="Home">
            <div className="logo">
              <img
                src={'/assets/MeirLogo.svg'}
                alt="Meir Profile"
                className="size-16"
              />
            </div>
          </Link>
          <ul className="flex p-4 text-xl font-lucky tracking-wide mt-2 sm:mt-1 flex-wrap text-center justify-center">
            <NavButton to="/" text="HOME" />
            <NavButton to="/about" text="ABOUT" />
            <NavButton to="/contact" text="CONTACT" />
            {/* <NavButton to="/wip" text="Experiments" /> */}
            {/* <NavButton to="/tools" text="Tools" /> */}
            {/* <NavButton to="/resume" text="Resume" /> */}
            <DropdownButton />
            {/* <MyNavLink to="/ai" text="AI" /> */}
          </ul>
        </nav>
      </header>
      {/* <RouterProvider router={router} /> */}
      {/* <RouterProvider router={router} fallbackElement={<LoadingPage />} /> */}
      {/* <Router> */}
    </>
  )
}
