import React, { useState } from 'react'
import { AnimatedButton } from '../pages/Contact'

function GoogleCalendar({ autoLoad = false, fullScreen = false }) {
  const [isLoaded, setIsLoaded] = useState(autoLoad)

  const loadCalendar = () => {
    setIsLoaded(true)
  }

  return (
    <div className={`w-full min-h-screen`}>
      {!isLoaded ? (
        <AnimatedButton text="Book an appointment" onClick={loadCalendar} />
      ) : (
        <iframe
          title="Google Calendar"
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2TOWLDCKJJd9TGPg9dCo-x_ZKnYJmM8SBGEb7STFbkmN0a5IeR4HJxUphm7adRpT2jM_U2Duez?gv=true"
          style={{
            border: 0,
            height: fullScreen ? '100vh' : '700px',
            minHeight: fullScreen ? '700px' : '700px',
          }}
          width="100%"
          frameBorder="0"
        ></iframe>
      )}
    </div>
  )
}

export default GoogleCalendar
