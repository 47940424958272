import React, { useEffect } from 'react'
import {
  Code,
  Server,
  Terminal,
  Flag,
  Cpu,
  Database,
  GitBranch,
  ArrowUpRight,
  ChevronRight,
  Download,
  ClipboardList,
  // eslint-disable-next-line no-unused-vars
  BarChart,
  Globe,
  Pencil,
  Shield,
} from 'lucide-react'
import { ContactBody } from '../Contact'
import { trackEvent } from '../../utils/gtagTracking'

const SKILLS = {
  programming: {
    icon: Code,
    title: 'Programming Languages',
    items: [
      'JavaScript',
      'TypeScript (Basic)',
      'Python',
      'PHP',
      'Rust (Exposure)',
    ],
  },
  frameworks: {
    icon: Server,
    title: 'Frameworks & Libraries',
    items: [
      'React',
      'Tailwind CSS',
      'Bootstrap CSS',
      'Express JS',
      'GraphQL',
      'REST',
    ],
  },
  webTechnologies: {
    icon: Globe,
    title: 'Web Technologies',
    items: [
      'AJAX',
      'WebRTC',
      'WebSocket',
      'Socket.IO',
      'API Integration',
      'PWA Development',
    ],
  },
  design: {
    icon: Pencil,
    title: 'Design & Prototyping Tools',
    items: ['Figma', 'Responsive Web Design'],
  },
  systems: {
    icon: Terminal,
    title: 'Systems',
    items: [
      'LAN / WAN Networking',
      'Firewall Configuration',
      'Cloud Platform Fundamentals',
      'Docker',
      'Virtual Machine Configuration',
      'Windows OS Optimization',
      'MacOS Optimization',
      "Raspberry Pi's",
      'PowerShell / CMD / Terminal',
      'DNS Configuration',
    ],
    order: 'length',
  },
  hardware: {
    icon: Cpu,
    title: 'Hardware & Automation',
    items: [
      'Automation Scripting',
      'IoT Devices',
      'Payment System Integration',
      'Arcade Machine Components',
      '3D Printing',
      'NFC',
      'QR Codes',
      'April Tags',
    ],
    order: 'length',
  },
  database: {
    icon: Database,
    title: 'Database Technologies',
    items: ['MySQL', 'PostgreSQL', 'MongoDB', 'Google Sheets', 'JSON'],
    order: 'abc',
  },
  versionControl: {
    icon: GitBranch,
    title: 'Version Control',
    items: ['Git', 'GitHub', 'GitLab', 'GitHub Workflows'],
  },
  projectManagement: {
    icon: ClipboardList,
    title: 'Project Management',
    items: ['Monday.com', 'Jira', 'Trello'],
    order: 'abc',
  },
  security: {
    icon: Shield,
    title: 'Security & Cybersecurity',
    items: [
      'Network Security',
      'Backend Hardening',
      'Data Encryption',
      'DevOps',
      'JWT Authentication',
      'CAPTCHAs',
      'Cloudflare',
      'MFA / 2FA / OTP',
      'Penetration Testing Tools',
      'Wireshark',
      'Email Security',
    ],
    order: 'length',
  },
  other: {
    icon: Code,
    title: 'Other',
    items: [
      'HTML / CSS',
      'Node.js',
      'PhpMyAdmin',
      'Regex',
      'Agile Development',
      'Performance Optimization',
    ],
    order: 'length',
  },
  languages: {
    icon: Flag,
    title: 'Languages',
    items: [
      'English & Hebrew (Native)',
      'Yiddish (Basic)',
      'French / Chinese / Japanese (Elementary)',
    ],
  },
}

// Reusable component for items with an icon and optional link
const InfoItem = ({ icon, children, href, isImage = false, ...props }) => {
  const content = (
    <>
      <span className="mr-1 select-none">{icon}</span>
      {href ? (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="underline hover:text-mantis-600 transition-colors"
          {...props}
        >
          {children}
        </a>
      ) : (
        <span>{children}</span>
      )}
    </>
  )

  return <div className="flex items-center">{content}</div>
}

const Separator = () => <span className="select-none">|</span>

const Header = () => {
  return (
    <>
      <header className="mb-6 items-center flex flex-col">
        <h1 className="text-3xl text-gray-800 font-lucky tracking-widest">
          MEIR KNAPP
        </h1>
        <div className="flex flex-wrap gap-4 text-gray-600 items-center justify-evenly my-4">
          {/* <div> */}
          <InfoItem
            icon="🗺️" // Using a map emoji as an icon
            href="https://www.google.com/maps/place/New+York,+NY"
          >
            <span className="underline">NYC</span>
          </InfoItem>
          <Separator />
          <InfoItem icon="📧" href="mailto:hello@meir.pro">
            <span className="print:hidden">Email</span>
            <span className="screen:hidden">hello@meir.pro</span>
          </InfoItem>
          <Separator />
          <InfoItem
            icon={
              <img
                alt="LinkedIn"
                height="15"
                width="15"
                src="/assets/icons/linkedin.svg"
              />
            }
            href="https://www.linkedin.com/in/meir-k/"
          >
            LinkedIn
            {/* <span className="print:hidden">LinkedIn</span> */}
            {/* <span className="screen:hidden">
              https://www.linkedin.com/in/meir-k/
              </span> */}
          </InfoItem>
          <Separator />
          <InfoItem
            icon={
              <img
                alt="GitHub"
                height="15"
                width="15"
                src="/assets/icons/github.svg"
                // src="https://cdn.jsdelivr.net/npm/simple-icons@v14/icons/github.svg"
              />
            }
            href="https://www.github.com/light-wing/"
          >
            GitHub
            {/* <span className="print:hidden">GitHub</span> */}
            {/* <span className="screen:hidden">
              https://www.github.com/light-wing/
            </span> */}
          </InfoItem>
          <Separator />
          <InfoItem icon="🌐" href="https://meir.pro">
            meir.pro
          </InfoItem>
        </div>
        <p className="text-gray-600 items-center flex flex-wrap">
          Technology Leader | Full-Stack Developer | Systems Engineer
        </p>
      </header>
    </>
  )
}
const ProfessionalExperience = () => {
  return (
    <>
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-3 border-b pb-2">
          Professional Experience
        </h2>
        <div>
          <h1 className="font-bold text-lg">VR 360 Action - OVRDRV Games</h1>
          <h2 className="font-bold text-base">Chief Technical Officer</h2>

          <h3 className="text-gray-500">2018 - Dec 2024</h3>
          <h3 className="text-gray-500 pt-2">
            {/* <span>About company:</span>
            <br /> */}
            VR 360 Action buys and upgrades arcade machines to accommodate U.S.
            customers as well as designs and produces its own virtual reality
            machines and immersive games. Now part of OVRDRV Games, it is
            recognized as the only arcade virtual reality experience with
            redemption-based gameplay.
          </h3>
          <p className="py-4">
            <span>My role:</span>
            <br />
            Personally contributed to the design of games, including systems
            that optimize communication between hardware and software.
            <br />
            Installed games in over 45 US locations in person and more remote
            installations. Each installation featured multiple machines,
            included training staff and providing technical support.
            <br />
            Managed over 5 developers locally and offshore, collaborating with
            over 20 customer support personnel from different teams to ensure
            clients receive the best and fastest resolutions.
          </p>
          <ul className="list-disc list-inside text-gray-700 text-sm space-y-2 mb-4">
            <li>
              <strong>Systems Optimization:</strong> Developed a customized
              Windows OS installation package that significantly reduced machine
              setup time by 80-90%. This enabled faster team deployment and
              standardized machine configurations across the organization.
            </li>
            <li>
              <strong>Communication Infrastructure:</strong> Designed and
              implemented a comprehensive API protocol, facilitating seamless
              communication between multiple developer teams. This established a
              unified information exchange system, enhancing project
              coordination and reducing communication overhead.
            </li>
            <li>
              <strong>Backend Infrastructure:</strong> Established centralized
              backend servers for file management and analytics, creating a
              robust and scalable system.
            </li>
            <li>
              <strong>Network Architecture:</strong> Implemented internal LAN
              networks and external firewall configurations, ensuring robust
              security protocols and seamless network connectivity throughout
              the organizational infrastructure.
            </li>
            <li>
              <strong>Merger integration:</strong> Managed certain issues
              related to OVRDRV’s acquisition of VR 360 Action: rebranding, new
              suppliers, adding redemption, developing proprietary games.
            </li>
            <li>
              <strong>Technical Support:</strong> Managed comprehensive IT
              infrastructure, providing rapid resolution for network, printer,
              and WiFi challenges. This minimized operational disruptions and
              enhanced overall system reliability.
            </li>
            <li>
              <strong>Technical Design:</strong> Engineered VR arcade machine
              systems, developing critical communication protocols between game
              components, hardware interfaces, and software launchers.
            </li>
            <li>
              <strong>IT Management: </strong>
              Provided rapid resolution for network and hardware challenges,
              minimizing downtime and maintaining operational reliability.
            </li>
          </ul>
          <span className="pt-8">
            Promoted from Technician/Engineer to Chief Technology Officer (CTO).
          </span>
        </div>
      </section>
    </>
  )
}
const TechnicalExperience = () => {
  return (
    <>
      <h2 className="text-2xl font-bold mb-6 border-b pb-3">
        Technical Experience
      </h2>
      {Object.values(SKILLS).map(({ icon: Icon, title, items, order }) => {
        let sortedItems = [...items] // Create a copy to avoid modifying the original array
        if (order === 'abc') {
          sortedItems.sort()
        } else if (order === 'length') {
          sortedItems.sort((a, b) => a.length - b.length)
        }
        return (
          <div key={title} className="mb-6">
            <h3 className="font-semibold text-lg mb-2 flex items-center justify-between transition-all duration-200 hover:translate-x-2 hover:text-opacity-80">
              <span className="mr-2">{title}</span>
              <Icon className="mr-2" size={20} />
            </h3>
            <ul
              className="text-sm list-disc flex flex-wrap"
              onCopy={(e) => {
                e.preventDefault()
                const text = sortedItems.join(', ')
                e.clipboardData.setData('text/plain', text)
              }}
            >
              {sortedItems.map((item) => (
                <li key={item} className="ml-6">
                  {item}
                </li>
              ))}
            </ul>
            {/* <div className="text-sm">{sortedItems.join(', ')}</div> */}
          </div>
        )
      })}
    </>
  )
}

const ProjectLink = ({ title, subtitle, text, url, className = '' }) => {
  const target = '_blank'
  const rel = 'noopener'
  return (
    <>
      <div className="flex items-center ">
        <h3 className="font-bold mr-1">{title}</h3>
        <ChevronRight className="mr-1" size={14} />
        <a
          href={url}
          target={target}
          rel={rel}
          className={`text-mantis-600 text-sm flex items-center hover:text-mantis-700 hover:translate-x-1 transition-all duration-200 ${className}`}
        >
          <span className="mr-1">{text}</span>
          <ArrowUpRight size={14} />
        </a>
      </div>
      <p className="text-gray-500">{subtitle}</p>
    </>
  )
}
const Education = () => {
  const show = false
  if (!show) return <></>
  return (
    <>
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-3 border-b pb-2">Education</h2>
        <div className="space-y-4">
          <div>
            <h3 className="font-bold text-lg">
              Practical Electrical Engineering
            </h3>
            <p className="text-gray-500">
              2-Year Professional Degree | 2015-2017
            </p>
            <ul className="list-disc list-inside text-gray-700 text-sm space-y-1">
              <li>
                Graduated with honors - Top 5 in class (95th percentile
                nationally)
              </li>
              <li>Focus on practical applications and system design</li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold text-lg">
              Network Administration & Computer Hardware
            </h3>
            <p className="text-gray-500">
              2-Year Technical Program | 2013-2015
            </p>
            <ul className="list-disc list-inside text-gray-700 text-sm space-y-1">
              <li>Microsoft MCSA Certification</li>
              <li>
                Comprehensive study of network infrastructure and hardware
                systems
              </li>
            </ul>
          </div>
          {/* 
              <div>
                <h3 className="font-bold text-lg">High School Diploma</h3>
                <ul className="list-disc list-inside text-gray-700 text-sm space-y-1">
                  <li>Advanced Placement (AP) equivalent in Mathematics</li>
                  <li>Focus on STEM subjects</li>
                </ul>
              </div> */}
          <p className="text-gray-700">
            Always learning and never stopping my pursuit of knowledge.
          </p>
        </div>
      </section>
    </>
  )
}
const Websites = () => {
  return (
    <>
      <section>
        <h2 className="text-xl font-semibold mb-3 border-b pb-2">
          Website Projects
        </h2>
        <div className="space-y-2">
          <ProjectLink
            title="Personal Website"
            subtitle="My personal website, always evolving with new features and updates as I find the time."
            text="meir.pro"
            url="https://meir.pro"
          />
          <ProjectLink
            title="Knapp Cartoons"
            subtitle="Your go-to for the finest caricature artwork, crafted by talented artists who bring your vision to life."
            text="knappcartoons.com"
            url="https://knappcartoons.com"
          />
        </div>
      </section>
    </>
  )
}
const DownloadButton = ({ pdfUrl, filename, buttonText }) => {
  const handleDownload = async () => {
    try {
      const response = await fetch(pdfUrl)
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading PDF:', error)
    }
  }

  const handleDownloadWithAnalytics = () => {
    trackEvent({
      eventName: 'download',
      label: 'pdf_download',
      category: 'resume',
    })
    handleDownload()
  }
  return (
    <button
      onClick={handleDownloadWithAnalytics}
      className="p-0 m-0 justify-center flex"
    >
      <div className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-lg transition-colors flex flex-row">
        <Download className="mr-2" size={16} />
        {buttonText}
      </div>
    </button>
  )
  // return (
  //   <a
  //     href={pdfUrl}
  //     download={filename}
  //     className="p-0 m-0 justify-center flex"
  //   >
  //     <button className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-lg transition-colors flex flex-row">
  //       <Download className="mr-2" size={16} />
  //       {buttonText}
  //     </button>
  //   </a>
  // )
}
const GeneralLinkButton = ({ url, buttonText, newTab = false }) => {
  return (
    <a
      href={`${url}`}
      {...(newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      onClick={() => {
        trackEvent({
          eventName: 'navigation_click',
          label: 'google_docs_resume',
          category: 'navigation',
        })
      }}
    >
      <button
        className={`${
          newTab
            ? ' bg-blue-500 hover:bg-blue-700'
            : ' bg-green-500 hover:bg-green-700'
        } text-white px-4 py-2 rounded-lg transition-colors duration-200`}
      >
        {buttonText}
      </button>
    </a>
  )
}

const ResumeContent = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const secretKey = urlParams.get('show')
  const quickShare = 'all'
  const showAll = secretKey === quickShare
  return (
    <>
      <div className="relative self-start lg:content-center scrollbar-hide w-screen overflow-auto">
        <div
          id="resume"
          //width 150vw (on phones only) - 1/3 goes to sidebar, 2/3 goes to content, leaving content with 100vw
          className="w-[150vw] md:w-[1000px] flex bg-white border print:border-none print:shadow-none my-8 rounded-lg print:rounded-none"
        >
          {/* Skills Sidebar */}
          <div
            id="sidebar"
            className="w-1/3 bg-mantis-600 text-white p-6 print:bg-mantis-600 rounded-l-lg print:rounded-none print:text-white print:[-webkit-print-color-adjust:exact] print:[print-color-adjust:exact] print:h-[100vh]"
          >
            <TechnicalExperience />
          </div>
          {/* Main Content */}
          <div className="w-2/3 md:min-w-min p-8" id="main">
            <Header />

            <ProfessionalExperience />

            <Education />

            {showAll && <Websites />}
          </div>
        </div>
      </div>
    </>
  )
}

const Resume = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const secretKey = urlParams.get('show')
  const quickShare = 'all'
  const showAll = secretKey === quickShare
  const [showGoogleSheet, setShowGoogleSheet] = React.useState(!showAll)
  useEffect(() => {
    document.title = 'Meir Knapp - Resume'
  }, [])
  // Add useEffect for print event listeners

  return (
    <>
      <div className="flex flex-col items-center lg:content-center scrollbar-hide overflow-auto">
        <div className="px-2 grid md:grid-flow-col1 gap-2 items-center justify-center content-center self-center text-center object-center pb-2">
          <div className="grid grid-row md:grid-flow-col gap-2">
            <GeneralLinkButton
              url={
                'https://docs.google.com/document/d/1wIjcRP_AB86pOonYwA8iMF00qE_2mC6GSSum0QVSjdY/view?usp=sharing'
              }
              newTab={true}
              buttonText={'Open Resume in Google Docs'}
            />
            {showAll && (
              <button
                onClick={() => setShowGoogleSheet(!showGoogleSheet)}
                className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded-lg transition-colors duration-200"
              >
                {showGoogleSheet
                  ? 'Show Colorful Resume'
                  : 'Show Traditional Resume'}
              </button>
            )}
            <DownloadButton
              pdfUrl={
                'https://docs.google.com/document/d/1wIjcRP_AB86pOonYwA8iMF00qE_2mC6GSSum0QVSjdY/export?format=pdf'
              }
              filename={`meir-knapp-resume-${new Date()
                .toISOString()
                .slice(0, 10)}.pdf`}
              buttonText={'Download PDF'}
            />
          </div>
        </div>

        {showGoogleSheet ? (
          <div className="relative flex text-center w-full h-screen rounded-none shadow-lg border border-gray-300 ">
            <iframe
              src="https://docs.google.com/document/d/e/2PACX-1vR4aq-8wsB2roh2CnnRRpZwfvbERgU0weAgWQF47riTd-ZqdG1z8QRavxCg9ypxLTdYGuESqqldS1BB/pub?embedded=true"
              title="Google Sheets Resume Data"
              className="scrollbar-hide w-full h-full "
            >
              Resume from Google docs - Loading...
            </iframe>
          </div>
        ) : (
          <ResumeContent />
        )}
      </div>
      <div className="mt-20 px-2">
        <ContactBody />
      </div>
    </>
  )
}
export default Resume
