import React, { useEffect, useRef, useState } from 'react'
import './App.css'
import './index.css'
import Index from './pages'
import FromChecker from './utils/sourceChecker'
import { Nav } from './parts/Nav/Nav'
import { Route, Switch, useLocation } from 'wouter'
import Footer from './parts/footer.js'
import TechnicalInformation from './pages/SharePage/TechnicalInformation.js'
import WIPExperiments from './pages/WIPExperiments.js'
import OnlineTools from './pages/OnlineTools.js'
import Resume from './pages/resume/Resume.js'
import About from './pages/About.js'
import Contact from './pages/Contact.js'
import ResumeAI from './pages/resume/ResumeAI.js'
// import Nav from "./parts/Nav/Nav";
// import LoadingPage from "./parts/LoadingPage/loading";
// import DOMCleanup from "./utils/DOMCleanup";
import { useFirstMountState } from 'react-use'
import AboutMeAI from './pages/ai/index.js'
import UrlHelper from './utils/UrlHelper'
import Appointments from './pages/Appointments.js'

const App = () => {
  const isFirstMount = useFirstMountState()
  //https://streamich.github.io/react-use/?path=/story/state-usefirstmountstate--docs
  const [location] = useLocation()
  const [currentRoute, setCurrentRoute] = useState(location)
  const previousLocation = useRef(location)
  const [animation, setAnimation] = useState('')

  useEffect(() => {
    if (isFirstMount) {
      // console.log('first load')
      return // Skip animation on first load
    }

    // // Determine navigation type (backward or forward)
    // const isBackNavigation = currentIndex < previousIndex
    const isBackNavigation = Math.random() < 0.5
    // // Update animation based on navigation type
    setAnimation(isBackNavigation ? 'slide-out-reverse' : 'slide-out')

    // Schedule animation for route change
    const timeoutId = setTimeout(() => {
      setCurrentRoute(location) // Update the route
      setAnimation(isBackNavigation ? 'slide-in-reverse' : 'slide-in')
    }, 500) // match timing with css file

    // Save current location for next comparison
    previousLocation.current = location

    // Clean up timeout
    return () => clearTimeout(timeoutId)
  }, [isFirstMount, location])

  return (
    <>
      <FromChecker />
      <UrlHelper />
      <Nav />
      <div
        className={`max-w-screen-lg mx-auto min-h-[80vh] flex flex-col justify-center relative ${animation}`}
      >
        <Switch location={currentRoute}>
          <Route path="/data" component={TechnicalInformation} />
          <Route path="/wip" component={WIPExperiments} />
          <Route path="/tools" component={OnlineTools} />
          <Route path="/resume" component={Resume} />
          <Route path="/resumeai" component={ResumeAI} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/appointments" component={Appointments} />
          <Route path="/ai" component={AboutMeAI} />
          <Route path="/test1" component={() => <>Test 1</>} />
          <Route path="/test2" component={() => <>Test 2</>} />
          <Route path="/" component={Index} />
          <Route>
            {(params) => {
              const path = params['*'].split(/[?#]/)[0]
              return (
                <center>
                  <b>404:</b> The page <code>"/{path}"</code> isn't ready yet!
                </center>
              )
            }}
          </Route>
        </Switch>
      </div>
      <Footer />
    </>
  )
}

export default App
